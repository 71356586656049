<template>
  <v-card flat>
    <v-card-title>
      Users
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="allUsers" :search="search">
      <template v-slot:item.photoURL="{item}">
        <v-avatar class="my-2" size="48" color="red">
          <img :src="'../' + item.photoURL" alt />
        </v-avatar>
      </template>
      <template v-slot:item.action="{item}">
        <v-btn v-if="currentUser.id != item.id" @click="deleteUser(item.id)" icon color="error">
          <v-icon small>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Users",
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Photo",
          align: "left",
          sortable: false,
          value: "photoURL"
        },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        { text: "Action", value: "action" }
      ]
    };
  },
  computed: {
    ...mapGetters(["allUsers", "currentUser"])
  },
  methods: {
    ...mapActions(["bind", "delete"]),
    fetchUsers() {
      if (!this.allUsers) {
        this.bind({
          collection: "users",
          data: "users"
        });
      }
    },
    deleteUser(id) {
      if (this.currentUser.role === "admin" && this.currentUser.id != id) {
        this.delete({
          collection: "users",
          doc: id
        });
      }
    }
  },
  created() {
    this.fetchUsers();
  }
};
</script>
